import { ChangeDetectionStrategy, Component } from '@angular/core';
import { rankings } from '../../../static/subnav-rankings.properties';
import { NavLinkComponent } from '../../nav-link/nav-link.component';
import { NavCardLandscapeComponent } from '../../nav-card-landscape/nav-card-landscape.component';

@Component({
	selector: 'uc-subnav-rankings',
	templateUrl: './subnav-rankings.component.html',
	standalone: true,
	imports: [NavLinkComponent, NavCardLandscapeComponent],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SubnavRankingsComponent {
	data = rankings;
}
