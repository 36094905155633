import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { advice } from '../../../static/subnav-advice.properties';
import { getStateNavArticles } from '../../../data-access/+store/navigation.selectors';
import { Environment } from '@uc/utilities';
import { AsyncPipe, NgClass } from '@angular/common';
import { NavLinkComponent } from '../../nav-link/nav-link.component';
import { NavBrowseAllComponent } from '../../nav-browse-all/nav-browse-all.component';

@Component({
	selector: 'uc-subnav-advice',
	templateUrl: './subnav-advice.component.html',
	standalone: true,
	imports: [NgClass, AsyncPipe, NavLinkComponent, NavBrowseAllComponent],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SubnavAdviceComponent implements OnInit {
	data = advice;
	articles$ = this.store.select(getStateNavArticles);
	clearingEnabled: boolean;

	constructor(
		@Inject('environment')
		environment: Environment,
		private store: Store,
	) {
		this.clearingEnabled = environment.clearingEnabled;
	}

	ngOnInit(): void {
		// Sets nav menu last item depending on clearing enabled.
		const navMenuColumnThree = this.data.sublinks[2];
		let navLink = navMenuColumnThree.links[3];
		navLink = this.data.methods.setClearingNavMenu(this.clearingEnabled);
		this.data.sublinks[2].links[3] = navLink;
	}
}
