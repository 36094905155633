@if (featuredUni$ | async; as featured) {
	<div class="mt-12 md:hidden">
		<h2 class="secondary-header !mb-2">Featured University ✨</h2>
		<p class="text-base text-gray-500">
			Unsure where to look first? Take a look at our featured university.
		</p>
	</div>
	<div class="-mt-3 lg:-mt-0">
		<uc-engage-card-cta
			ucImpression
			(onImpression)="
				onUniImpression($event, featured.id, featured.name, featured.order)
			"
			(ctaClicked)="onUniProfileClick(featured.id, featured.name, featured.order)"
			[backgroundImage]="featured.profile.cover_image"
			icon="building.svg"
			iconText="Featured University"
			[hideIconOnMobile]="true"
			[cardTitle]="featured.name"
			[description]="
				featured.address.region +
				' · ' +
				(featured.stats.recommended_percentage > 0
					? featured.stats.recommended_percentage + '% Recommended'
					: '')
			"
			[link]="'/universities/' + featured.slug"
			[uniId]="featured.id"
			[enableSave]="true"
			componentName="Featured University"
			[isSaved]="featured.saved ? 1 : 0"
			[ugSearchOrderPosition]="featured.order"
			[cleSearchOrderPosition]="null"
		>
			@if (featured.stats.recommended_percentage === 0) {
				<uc-write-review-btn
					leave-review
					[universityId]="featured.id"
					[universityName]="featured.name"
					[universityUrl]="featured.slug"
					buttonText="Leave a review"
					customStyles="text-base"
				/>
			}

			<div class="grid grid-cols-2 gap-3 -mt-4 md:-mt-0 md:flex">
				<uc-request-btn
					[params]="featured.id"
					componentName="Featured University"
					customStyles="!w-full md:w-auto request-info-btn"
					product="undergraduate"
					[ugSearchOrderPosition]="featured.order"
				/>
				<uc-visit-website-btn
					customStyles="w-full md:w-auto secondary-button"
					[externalUrl]="featured.profile.external_url"
					product="undergraduate"
					componentName="Featured University"
					[uniId]="featured.id"
					buttonText="Visit Website"
					[source]="featured.name"
					sourceType="uni"
					[ugSearchOrderPosition]="featured.order"
					[uniName]="featured.name"
				/>
			</div>
		</uc-engage-card-cta>
	</div>
}
