import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { map } from 'rxjs';
import { events, landingCard } from '../../../static/subnav-events.properties';
import { NavCard } from '../../../models/navigation.models';
import { fetchPromoSlots, getDefaultPromoSlotsData } from '@uc/web/shared/data-access';
import { PromoSlot, PromoSlotComponentName } from '@uc/web/shared/data-models';
import { HelperService } from '@uc/utilities';
import { formatDataForInfoCard } from '../../../utils/subnav-events.utils';
import { NavLinkComponent } from '../../nav-link/nav-link.component';
import { AsyncPipe } from '@angular/common';
import { NavCardLandscapeComponent } from '../../nav-card-landscape/nav-card-landscape.component';
import { NavBrowseAllComponent } from '../../nav-browse-all/nav-browse-all.component';
import { InAppBrowserService, PromoSlotGTMService } from '@uc/web/core';

@Component({
	selector: 'uc-subnav-events',
	templateUrl: './subnav-events.component.html',
	standalone: true,
	imports: [
		AsyncPipe,
		NavLinkComponent,
		NavCardLandscapeComponent,
		NavBrowseAllComponent,
	],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SubnavEventsComponent implements OnInit {
	data = events;
	landingCard = landingCard;

	// always show UC content cards
	$contentCards = this._store.select(getDefaultPromoSlotsData).pipe(
		map((cards) => {
			if (!Array.isArray(cards) || cards.length === 0) {
				return events.furtherInformation;
			} else {
				const final: NavCard[] = [];
				const copy: PromoSlot[] = this._helperSrv.deepCopyObject(cards);

				copy.slice(0, 2).forEach((card) => {
					const edited = formatDataForInfoCard(card);
					final.push(edited);
				});

				return final;
			}
		}),
	);

	constructor(
		private _store: Store,
		private _helperSrv: HelperService,
		private _promoSlotGTMSrv: PromoSlotGTMService,
		private _isInAppBrowserSrv: InAppBrowserService,
	) {}

	ngOnInit(): void {
		const platform = this._isInAppBrowserSrv.getPlatform();
		this._store.dispatch(fetchPromoSlots({ platform }));
	}

	trackPromoSlotIndex(index: number, card: NavCard) {
		const slotNumber = index + 1;
		this._promoSlotGTMSrv.clickPromoSlot(
			slotNumber,
			PromoSlotComponentName.NAV,
			card.uniId,
			card.uniName,
		);
	}
}
