import {
	CUSTOM_ELEMENTS_SCHEMA,
	ChangeDetectionStrategy,
	Component,
	ElementRef,
	OnInit,
	ViewChild,
	afterNextRender,
	input,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { fetchArticlesCarouselData } from './data-access/+state/articles-carousel.actions';
import { getArticlesCarouselData } from './data-access/+state/articles-carousel.selectors';
import { AsyncPipe, NgOptimizedImage } from '@angular/common';
import { DescriptionComponent } from '@uc/shared/ui';
import { RouterLink } from '@angular/router';
import {
	CategoryLinkPipe,
	CategoryNamePipe,
	CdnPathPipe,
	UcDatePipe,
} from '@uc/utilities';

@Component({
	selector: 'uc-articles-carousel',
	templateUrl: './articles-carousel.component.html',
	standalone: true,
	imports: [
		AsyncPipe,
		RouterLink,
		NgOptimizedImage,
		CdnPathPipe,
		CategoryLinkPipe,
		CategoryNamePipe,
		UcDatePipe,
		DescriptionComponent,
	],
	changeDetection: ChangeDetectionStrategy.OnPush,
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ArticlesCarouselComponent implements OnInit {
	categoryId = input(11);
	year = input(new Date().getFullYear());
	@ViewChild('swiperRef') protected swiperRef: ElementRef | undefined;

	clearingArticles$ = this._store.select(getArticlesCarouselData);

	constructor(private _store: Store) {
		afterNextRender(() => {
			this._initSwiper();
		});
	}

	ngOnInit(): void {
		this._store.dispatch(
			fetchArticlesCarouselData({ categoryId: this.categoryId() }),
		);
	}

	private _initSwiper() {
		const config = {
			slidesPerView: 1.1,
			spaceBetween: 24,
			loop: false,
			noSwipingClass: 'swiper-no-swiping',
			navigation: {
				nextEl: `.articles-prev`,
				prevEl: `.articles-next`,
			},
			pagination: {
				type: 'fraction',
				el: `.articles-pagination`,
			},
			breakpoints: {
				// when window width is >= 480px
				590: {
					slidesPerView: 2,
					spaceBetween: 32,
					slidesPerGroup: 2,
				},
				// when window width is >= 968px
				879: {
					slidesPerView: 3,
					spaceBetween: 32,
					slidesPerGroup: 3,
				},
			},
		};

		const swiperEl = this.swiperRef?.nativeElement;
		Object.assign(swiperEl, config);

		swiperEl.initialize();
	}

	getAbsolutePath(url: string) {
		return url[0] !== '/' ? `/${url}` : url;
	}
}
