import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { NavLinkClickService } from '@uc/web/core';
import { BaseNavlinkComponent } from '../base-navlink.component';
import { Link } from '../../models/navigation.models';
import { RouterLink } from '@angular/router';
import { NgClass } from '@angular/common';
import { NewBadgeSvgComponent } from '@uc/shared/svg';

@Component({
	selector: 'uc-nav-link',
	templateUrl: './nav-link.component.html',
	standalone: true,
	imports: [RouterLink, NgClass, NewBadgeSvgComponent],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavLinkComponent extends BaseNavlinkComponent {
	basePath = input('');
	paramKey = input('');
	linkData = input.required<Link>();

	constructor(public navLinkClickSrv: NavLinkClickService) {
		super();
	}

	getRouterParams(key: string, value: string) {
		return { [key]: value };
	}
}
